<template>
  <div>
    <div class="px-1 mb-5">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        @click="print()"
      >
        {{ $t("COMMON.EXPORT_EQUIPMENT_EXCEL") }}
      </base-button>
    </div>
    <equipment-list-table :filterEstablishment="establishment.id" />
  </div>
</template>

<script>
import swal from "sweetalert2";
import EquipmentListTable from "../../../InventoryModule/EquipmentManagement/partials/EquipmentListTable.vue";

export default {
  name: "establishment-view-equipments",

  components: {
    EquipmentListTable,
  },

  props: ["establishment"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const response = await this.$store.dispatch("equipments/print", {
          filters: {
            establishment: this.establishment.id,
          },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `warehouse-${this.establishment.id}-equipment.xlsx`
        );
        document.body.appendChild(link);
        link.click();

        this.selectedSuppliers = [];
        this.printModalOpened = false;
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        swal.close();
      }
    },
  },

  mounted() {},

  watch: {
    establishment(establishment) {},
  },
};
</script>

<template>
  <div>
    <div v-if="salesOrder.status == ORDER_STATUS_DRAFT" style="display: inline">
      <badge :type="getBadgeType()">
        {{ $t(`SALES_ORDERS.ORDER_STATUS_${salesOrder.status}`) }}
      </badge>
    </div>

    <div
      v-if="salesOrder.status == ORDER_STATUS_CANCELED"
      style="display: inline"
    >
      <badge :type="getBadgeType()">
        {{ $t(`SALES_ORDERS.ORDER_STATUS_${salesOrder.status}`) }}
      </badge>
    </div>

    <template
      v-if="
        ![ORDER_STATUS_DRAFT, ORDER_STATUS_CANCELED].includes(salesOrder.status)
      "
    >
      <div style="display: inline">
        <badge :type="getInvoicingBadgeType()">
          {{
            $t(`SALES_ORDERS.INVOICING_STATUS_${salesOrder.invoicing_status}`)
          }}
        </badge>
      </div>

      <div style="display: inline">
        &nbsp;>&nbsp;
        <badge :type="getDeliveryBadgeType()">
          {{ $t(`SALES_ORDERS.DELIVERY_STATUS_${salesOrder.delivery_status}`) }}
        </badge>
      </div>
    </template>
  </div>
</template>

<script>
import {
  orderStatusesOption,
  ORDER_STATUS_DRAFT,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_VALIDATED,
  INVOICING_STATUS_PENDING,
  INVOICING_STATUS_PARTIALLY_INVOICED,
  INVOICING_STATUS_INVOICED,
  DELIVERY_STATUS_PENDING,
  DELIVERY_STATUS_PARTIALLY_DELIVERED,
  DELIVERY_STATUS_DELIVERED,
} from "@/constants/orders";

export default {
  name: "sales-order-status-badge",

  components: {},

  mixins: [],

  props: ["salesOrder"],

  data() {
    return {
      statusesOptions: orderStatusesOption,
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      ORDER_STATUS_CANCELED: ORDER_STATUS_CANCELED,
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.salesOrder.status) {
        case ORDER_STATUS_DRAFT:
          return "primary";
        case ORDER_STATUS_CANCELED:
          return "danger";
        case ORDER_STATUS_VALIDATED:
          return "default";
        case ORDER_STATUS_COMPLETED:
          return "success";
        default:
          break;
      }
      return "default";
    },

    getInvoicingBadgeType() {
      switch (this.salesOrder.invoicing_status) {
        case INVOICING_STATUS_PENDING:
          return "default";
        case INVOICING_STATUS_PARTIALLY_INVOICED:
          return "primary";
        case INVOICING_STATUS_INVOICED:
          return "success";
        default:
          break;
      }
      return "default";
    },

    getDeliveryBadgeType() {
      switch (this.salesOrder.delivery_status) {
        case DELIVERY_STATUS_PENDING:
          return "default";
        case DELIVERY_STATUS_PARTIALLY_DELIVERED:
          return "primary";
        case DELIVERY_STATUS_DELIVERED:
          return "success";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>

<template>
  <div>
    <div class="row mb-5">
      <div class="col-12" style="display: flex; align-items: center">
        <span class="display-4">
          {{ `${establishment.company_name}` }} -
          {{ $t("COMMON.PURCHASES_ORDERS") }}
        </span>
        <base-button
          type="primary"
          icon
          size="sm"
          @click="
            () => {
              showSupplierSelectorModal = true;
            }
          "
          :disabled="loading"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-single-copy-04"></i>
            {{ $t("ESTABLISHMENTS.ADD_PURCHASES_ORDER") }}
          </span>
        </base-button>
      </div>
    </div>

    <purchases-order-list-table :filterEstablishment="establishment.id" />

    <modal
      :show="showSupplierSelectorModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <form class="" @submit.prevent="">
        <div>
          <base-input :label="`${$t(`PURCHASES_ORDERS.SELECT_SUPPLIERS`)} (*)`">
            <supplier-selector
              :allowNone="true"
              :filterable="true"
              :showAll="false"
              :filterOrganization="establishment.organization.id"
              @supplierChanged="
                (supplierId) => {
                  supplier.id = supplierId;
                }
              "
            />
          </base-input>
        </div>
      </form>

      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          :disabled="!supplier.id"
          @click="
            () => {
              addPurchasesOrder();
            }
          "
        >
          {{ $t("ESTABLISHMENTS.ADD_PURCHASES_ORDER") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="
            () => {
              showSupplierSelectorModal = false;
            }
          "
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import SupplierSelector from "@/components/SupplierSelector.vue";
import defaultPurchasesOrder from "../../../PurchasesModule/PurchasesOrderManagement/defaultPurchasesOrder";
import PurchasesOrderListTable from "../../../PurchasesModule/PurchasesOrderManagement/partials/PurchasesOrderListTable.vue";

export default {
  name: "establishment-view-orders",

  components: {
    PurchasesOrderListTable,
    SupplierSelector,
  },

  mixins: [],

  props: ["establishment"],

  data() {
    return {
      showSupplierSelectorModal: false,
      supplier: {
        type: "suppliers",
        id: null,
      },
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      organizationConfig: "organizationOptions/organizationConfig",
    }),
  },

  created() {},

  methods: {
    async addPurchasesOrder() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch(
        "organizationOptions/getOrganizationConfig",
        this.establishment.organization.id
      );

      this.loading = true;
      const purchasesOrderData = cloneDeep(defaultPurchasesOrder);
      delete purchasesOrderData.purchasesInvoice;
      purchasesOrderData.expiration_time = moment(
        purchasesOrderData.expiration_time
      ).toISOString();

      purchasesOrderData.issuer = this.supplier;
      purchasesOrderData.organization = this.establishment.organization;
      purchasesOrderData.allowedLocations = this.establishment.allowedLocations;
      purchasesOrderData.billing_entity_type =
        this.establishment.billing_entity_type;
      purchasesOrderData.billing_company_name =
        this.establishment.billing_company_name;
      purchasesOrderData.billing_firstname =
        this.establishment.billing_firstname;
      purchasesOrderData.billing_lastname = this.establishment.billing_lastname;
      purchasesOrderData.billing_country = this.establishment.billing_country;
      purchasesOrderData.billing_state = this.establishment.billing_state;
      purchasesOrderData.billing_city = this.establishment.billing_city;
      purchasesOrderData.billing_zipcode = this.establishment.billing_zipcode;
      purchasesOrderData.billing_address = this.establishment.billing_address;
      purchasesOrderData.billing_email = this.establishment.billing_email;
      purchasesOrderData.terms_conditions =
        this.organizationConfig.DEFAULT_PURCHASES_ORDER_TERMS;
      purchasesOrderData.description =
        this.organizationConfig.DEFAULT_PURCHASES_ORDER_DESCRIPTION;
      purchasesOrderData.paymentTerm = {
        type: "payment-terms",
        id: this.organizationConfig.DEFAULT_PURCHASES_PAYMENT_TERMS,
      };

      delete purchasesOrderData.cafeteria;
      purchasesOrderData.establishment = {
        type: "establishments",
        id: this.establishment.id,
      };
      purchasesOrderData.serviceCenter = {
        type: "service-centers",
        id: this.establishment.serviceCenter.id,
      };

      if(this.establishment.warehouse && this.establishment.warehouse.id) {
        purchasesOrderData.destinationWarehouse = {
          type: "warehouses",
          id: this.establishment.warehouse.id,
        };
      } else {
        delete purchasesOrderData.destinationWarehouse;
      }

      try {
        await this.$store.dispatch("purchasesOrders/add", purchasesOrderData);
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_ADDED"),
        });
        const purchasesOrder = await this.$store.getters[
          "purchasesOrders/purchasesOrder"
        ];
        this.$router.push({
          name: "View PurchasesOrder",
          params: { id: purchasesOrder.id },
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
      swal.close();
    },
  },

  mounted() {},

  watch: {
    establishment(establishment) {},
  },
};
</script>

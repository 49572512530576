export default {
  type: "weekly-reports",
  starts_at : null,
  ends_at : null,
  counter_sales : 0,
  billing : 0,
  distributor_sales : 0,
  counter_purchase : 0,
  stationery : 0,
  cleaning_and_disposable_products : 0,
  others : 0,
  maintenance : 0,
  distributor : 0,
  wages : 0,
  rent : 0,

  relationshipNames: [
    "organization",
    "establishment",
    "serviceCenter",
    "cafeteria",
  ],
  supplier: {
    type: "suppliers",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  cafeteria: {
    type: "cafeterias",
    id: null,
  },

  allowedUsers: [],

  
};

<template>
  <div class="container-fluid mt-5">
    <div>
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ establishment.company_name }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_ESTABLISHMENTS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editEstablishment()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_ESTABLISHMENTS) &&
                    !establishment.active
                  "
                  type="info"
                  icon
                  size="sm"
                  :disabled="loading"
                  @click="activateEstablishment()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-power-off"></i>
                    {{ $t("ESTABLISHMENTS.ACTIVATE") }}
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_ESTABLISHMENTS) &&
                    establishment.active
                  "
                  type="warning"
                  icon
                  size="sm"
                  :disabled="loading"
                  @click="deactivateEstablishment()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-power-off"></i>
                    {{ $t("ESTABLISHMENTS.DESACTIVATE") }}
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_DELETE_ESTABLISHMENTS)"
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteEstablishment()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_VIEW_WAREHOUSES) &&
                    establishment.warehouse
                  "
                  type="info"
                  icon
                  size="sm"
                  :title="$t('COMMON.WAREHOUSE')"
                  @click="showWarehouse()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-building"></i>
                  </span>
                </base-button>
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      printModalOpened = true;
                    }
                  "
                  v-if="$currentUserCan($permissions.PERM_CREATE_CASH_REGISTERS)"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="btn-inner--text"> Rapport hebdo </span>
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button @click="goBack()" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div class="col-12 justify-content-center justify-content-sm-between flex-wrap">
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <establishment-view-global :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="cafeterias"
                  id="2"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.CAFETERIAS") }}
                  </span>
                  <establishment-view-cafeterias :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="contacts"
                  id="3"
                  :active="true"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
                >
                  <span slot="title">
                    <i class="ni ni-circle-08" />
                    {{ $t("COMMON.CONTACTS") }}
                  </span>
                  <establishment-view-contacts :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="orders"
                  id="4"
                  :active="true"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SALES_ORDERS)"
                >
                  <span slot="title">
                    <i class="ni ni-bag-17" />
                    {{ $t("COMMON.SALES_ORDERS") }}
                  </span>
                  <establishment-view-sales-orders :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="orders"
                  id="5"
                  :active="true"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PURCHASES_ORDERS)"
                >
                  <span slot="title">
                    <i class="ni ni-bag-17" />
                    {{ $t("COMMON.PURCHASES_ORDERS") }}
                  </span>
                  <establishment-view-purchases-orders :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  v-if="establishment.has_warehouse"
                  title="establishment-products"
                  id="6"
                >
                  <span slot="title">
                    <i class="fa fa-cubes" />
                    {{ $t("COMMON.PRODUCTS") }}
                  </span>
                  <establishment-view-warehouse-products :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="allowed-users"
                  id="7"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
                >
                  <span slot="title">
                    <i class="ni ni-single-02" />
                    {{ $t("COMMON.USERS") }}
                  </span>
                  <allowed-users-view
                    :object="establishment"
                    :objectName="establishment.company_name"
                    :objectStore="'establishments'"
                    @objectUpdated="get"
                  />
                </tab-pane>

                <!-- <tab-pane
                  title="files"
                  id="998"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.FILES") }}
                  </span>
                  <list-file-component :object="establishment" />
                </tab-pane> -->
                <tab-pane title="equipments" id="998" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_EQUIPMENTS)">
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    Équipements
                  </span>
                  <establishment-view-equipments :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <establishment-view-logs :establishment="establishment" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
    <modal :show.sync="printModalOpened" modal-classes="modal-secondary" size="xl">
      <div>
        <h2>{{ $t("WEEKLY_REPORTS.ADD_WEEKLY_REPORT") }}</h2>
        <div>
          <base-input
            :label="`${$t('CASH_REGISTER_READINGS.STARTS_AT')} (*)`"
            :placeholder="$t('CASH_REGISTER_READINGS.STARTS_AT')"
          >
            <flat-picker
              :config="{
                allowInput: true,
                allowInput: true,
                enableTime: false,
                dateFormat: 'Y-m-d',

                locale: $flatPickrLocale(),
              }"
              class="form-control datepicker"
              v-model="weeklyReport.starts_at"
              @on-change="
                () => {
                  onFormChanged();
                }
              "
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.starts_at" />
          <base-input
            :label="`${$t('CASH_REGISTER_READINGS.ENDS_AT')} (*)`"
            :placeholder="$t('CASH_REGISTER_READINGS.ENDS_AT')"
          >
            <flat-picker
              :config="{
                allowInput: true,
                enableTime: false,
                dateFormat: 'Y-m-d',

                locale: $flatPickrLocale(),
              }"
              class="form-control datepicker"
              v-model="weeklyReport.ends_at"
              @on-change="
                () => {
                  onFormChanged();
                }
              "
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.ends_at" />

          <div class="my-4">
            <base-button
              type="button"
              class="btn btn-sm btn-primary"
              native-type="submit"
              @click="handleSubmitweeklyReport()"
            >
              {{ $t("COMMON.SAVE") }}
            </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
// import ListFileComponent from "@/components/File/ListFileComponent.vue";
import AllowedUsersView from "@/components/AllowedUsers/AllowedUsersView.vue";
import EstablishmentViewGlobal from "./partials/EstablishmentViewGlobal.vue";
import EstablishmentViewSalesOrders from "./partials/EstablishmentViewSalesOrders.vue";
import EstablishmentViewPurchasesOrders from "./partials/EstablishmentViewPurchasesOrders.vue";
import EstablishmentViewLogs from "./partials/EstablishmentViewLogs.vue";
import EstablishmentViewContacts from "./partials/EstablishmentViewContacts.vue";
import EstablishmentViewEquipments from "./partials/EstablishmentViewEquipments.vue";
import EstablishmentViewCafeterias from "./partials/EstablishmentViewCafeterias.vue";
import defaultEstablishment from "./defaultEstablishment";
import EstablishmentViewWarehouseProducts from "./partials/EstablishmentViewWarehouseProducts.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import flatPicker from "vue-flatpickr-component";
import defaultWeeklyReport from "./defaultWeeklyReport";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    EstablishmentViewGlobal,
    EstablishmentViewSalesOrders,
    EstablishmentViewPurchasesOrders,
    EstablishmentViewLogs,
    EstablishmentViewContacts,
    EstablishmentViewCafeterias,
    EstablishmentViewEquipments,
    // ListFileComponent,
    AllowedUsersView,
    EstablishmentViewWarehouseProducts,
    ValidationError,
    flatPicker,
  },

  mixins: [requestErrorMixin, formMixin],

  data() {
    const defaultWeekly = cloneDeep(defaultWeeklyReport);
    return {
      establishment: cloneDeep(defaultEstablishment),
      loading: false,
      printModalOpened: false,
      weeklyReport: defaultWeekly,
    };
  },

  created() {
    this.get();
  },

  methods: {
    onFormChanged() {
      this.$emit("formChanged");
    },
    async handleSubmitweeklyReport() {
      this.loading = true;
      const weeklyReportData = {
        ...this.weeklyReport,
        establishment: {
          type: this.establishment.type,
          id: this.establishment.id,
        },
        organization: {
          type: "organizations",
          id: this.establishment.organization.id,
        },
      };
      if (weeklyReportData.serviceCenter) {
        if (!weeklyReportData.serviceCenter.id) {
          delete weeklyReportData.serviceCenter;
        }
      }
      if (weeklyReportData.establishment) {
        if (!weeklyReportData.establishment.id) {
          delete weeklyReportData.establishment;
        }
      }
      if (weeklyReportData.cafeteria) {
        if (!weeklyReportData.cafeteria.id) {
          delete weeklyReportData.cafeteria;
        }
      }

      try {
        await this.$store.dispatch("weeklyReports/add", weeklyReportData);

        this.$notify({
          type: "success",
          message: this.$t("WEEKLY_REPORTS.WEEKLY_REPORT_ADDED"),
        });
        const weeklyReport = await this.$store.getters["weeklyReports/weeklyReport"];
        this.editWeeklyReport(weeklyReport);
        this.loading = false;
        this.printModalOpened = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const weeklyReport = await this.$store.getters["weeklyReports/weeklyReport"];
        if (weeklyReport.id) {
          await this.$store.dispatch("weeklyReports/destroy", cashRegister.id);
        }
        this.loading = false;
      }
    },

    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("establishments/get", id);
        this.establishment = this.$store.getters["establishments/establishment"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    viewWeeklyReport(weeklyReport) {
      this.$router.push({
        name: "View WeeklyReport",
        params: { id: weeklyReport.id },
      });
    },
    async editWeeklyReport(weeklyReport) {
      // this.alertLeave = false;
      this.$router.push({
        name: "Edit WeeklyReport",
        params: { id: weeklyReport.id },
      });
    },
    async editEstablishment(row) {
      this.$router.push({
        name: "Edit Establishment",
        params: { id: this.establishment.id },
      });
    },

    async showWarehouse(row) {
      this.$router.push({
        name: "View Warehouse",
        params: { id: this.establishment.warehouse.id },
      });
    },

    async deleteEstablishment() {
      const confirmation = await swal.fire({
        title: this.$t("ESTABLISHMENTS.DELETE_THIS_ESTABLISHMENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("establishments/destroy", this.establishment.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("ESTABLISHMENTS.ESTABLISHMENT_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Establishments" });
    },

    async activateEstablishment() {
      try {
        this.loading = true;
        await this.$store.dispatch("establishments/activate", this.establishment.id);
        this.establishment = this.$store.getters["establishments/establishment"];
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_ACTIVATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deactivateEstablishment() {
      try {
        this.loading = true;
        await this.$store.dispatch("establishments/deactivate", this.establishment.id);
        this.establishment = this.$store.getters["establishments/establishment"];
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_DEACTIVATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },
  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
